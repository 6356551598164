import { gql } from '@apollo/client';

export const fetchCandidatesCoverse = gql`
  query fetchCandidatesCoverse {
    candidates: candidates_employeecandidate(
      distinct_on: conversion_link
      where: { conversion_link: { _neq: "" } }
    ) {
      link: conversion_link
    }
  }
`;
export const fetchSiteParlorPage = gql`
  query fetchSiteParlorPage {
    siteParlorPage: core_siteparlorpage_by_pk(id: 1) {
      loyalty_page
      canva_admin_code
      canva_client_code
    }
  }
`;
export const fineTypesQuery = gql`
  query fetchFineTypes {
    accounts_finetype {
      id
      name
    }
  }
`;
export const STYLE = gql`
  query styles {
    styles: accounts_style {
      id
      name
      type: apps_sessions_sessiontype {
        id
      }
    }
  }
`;
export const CATEGORY = gql`
  query Prices {
    marketing: price_typemarketingcategory {
      id
      name
      comment
    }
    advertising: price_typeadvertisingbudget {
      id
      name
      comment
    }
  }
`;
export const PRICES = gql`
  query Prices(
    $parlor: Int!
    $servicesType: Int! = null
    $limit: Int
    $offset: Int
    $selectedCategory: Int!
    $selectSeasonality: [String!]
  ) {
    price: price_parlorprice(
      order_by: { id: desc }
      where: {
        parlor_id: { _eq: $parlor }
        price_priceservice: { apps_sessions_sessiontype: { id: { _eq: $servicesType } } }
        price_pricecategory: { id: { _eq: $selectedCategory } }
        seasonality: { _eq: $selectSeasonality }
      }
    ) {
      id
      director_comment
      approved
      discount
      note
      seasonality
      high_demand
      approved_director_comment
      director_comment_changes
      advertising: price_typeadvertisingbudget {
        id
        comment
        name
      }
      marketing: price_typemarketingcategory {
        id
        comment
        name
      }
      category: price_pricecategory {
        id
        name
        items: price_priceitems {
          id
          service_id
          price
          approved
          discount
          note
          high_demand
        }
      }
      service: price_priceservice {
        id
        studying
        studying_plan
        location: apps_sessions_sessiontypelocation {
          id
          name_ru
        }
        type: apps_sessions_sessiontype {
          id
          name_ru
        }
        price_priceitems {
          id
          price_pricecategory {
            id
            name
          }
          price
        }
      }
    }
  }
`;
export const questioning = gql`
  query QRCodeQuery($is_new: Boolean) {
    questioning: questioning_questionnaire(where: { is_new_employee: { _eq: $is_new } }) {
      id
      status_id
      title
      updated_at
      description
      is_active
      is_new_employee
      status: questioning_questionnairestatus {
        id
        title
        level_setting_id
        color: questioning_questionnairestatuscolor {
          id
          color_title
          color
          comment
        }
      }
      category: questioning_questionnairecategory {
        id
        name
        comment
      }
      requestions: questioning_questionnairequestions {
        id
        is_required
        title
        questionnaire_id
        type
        answer_option
        documents: accounts_typeofdocument {
          id
          value
          example_file
          format_of_file
          title
        }
      }
      questionnaire_parlors: questioning_questionnaire_parlors {
        id
        questionnaire_id
        questionnairesettingparlor_id
        parlorSetting: questioning_questionnairesettingparlor {
          roles
          title
          id
          settingparlor_parlors: questioning_questionnairesettingparlor_parlors {
            id
            parlor_id
            questionnairesettingparlor_id
          }
        }
      }
      accesUser: questioning_questionnaire_users {
        id
        user_id
        questionnaire_id
      }
    }
    completed: questioning_questionnairecompleted {
      id
      questionnaire_id
      status
      user_id
    }
    allStatus: questioning_questionnairestatus {
      id
      title
      level_setting_id
      color: questioning_questionnairestatuscolor {
        id
        color_title
        color
        comment
      }
    }
  }
`;
export const fetchEmployeesMentor = gql`
  query fetchEmployees($parlorId: Int!) {
    accounts_user(
      where: {
        accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
        role: { _in: ["director", "administrator", "master"] }
        is_active: { _eq: true }
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
      accounts_profile {
        accounts_mentorrank {
          id
          name
        }
      }
      motivation: motivation_motivation {
        mentor_percent
      }
      countStudent: studentsStudentsByMentorId_aggregate {
        ag: aggregate {
          count
        }
      }
    }
  }
`;
export const fetchEmployeesQueryFromShop = gql`
  query fetchEmployees($parlorId: Int!) {
    accounts_user(
      where: {
        accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
        is_active: { _eq: true }
        _or: [
          { role: { _in: ["director", "administrator", "master", "student"] } }
          { role: { _eq: "office" }, core_department: { name: { _eq: "Продажи" } } }
        ]
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
      accounts_profile {
        accounts_mentorrank {
          id
          name
        }
      }
      motivation: motivation_motivation {
        mentor_percent
      }
      countStudent: studentsStudentsByMentorId_aggregate {
        ag: aggregate {
          count
        }
      }
    }
  }
`;
export const fetchEmployeesQuery = gql`
  query fetchEmployees($parlorId: Int!) {
    accounts_user(
      where: {
        accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
        is_active: { _eq: true }
        role: { _in: ["director", "administrator", "master", "student"] }
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
      studentProfile: accounts_studentprofile {
        id
        who_studing
      }
      accounts_profile {
        accounts_mentorrank {
          id
          name
        }
      }
      motivation: motivation_motivation {
        mentor_percent
      }
      countStudent: studentsStudentsByMentorId_aggregate {
        ag: aggregate {
          count
        }
      }
    }
  }
`;
export const fetchStudentQuery = gql`
  query fetchEmployees($parlorId: Int!, $role: [String!]) {
    accounts_user(
      where: {
        accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
        is_active: { _eq: true }
        role: { _in: $role }
        studentsStudentsByStudentId: { finished: { _eq: false } }
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
      studentProfile: accounts_studentprofile {
        id
        who_studing
      }
      accounts_profile {
        accounts_mentorrank {
          id
          name
        }
      }
      motivation: motivation_motivation {
        mentor_percent
      }
      student: students_student {
        id
      }
      countStudent: studentsStudentsByMentorId_aggregate {
        ag: aggregate {
          count
        }
      }
    }
  }
`;

export const TYPEEDUCATION = gql`
  query fetchTypeOfEducation {
    typeEducations: students_typeeducation {
      id
      name
      comment
      count_practik
    }
  }
`;

export const CUSTOMER_FORM_STATUS = gql`
  query customerFormStatus {
    customers_formstatus {
      color
      id
      name
    }
  }
`;
export const SITE_FORM_NAMES = gql`
  query SiteFormNames {
    formNames: customers_siteform_aggregate(distinct_on: [name]) {
      nodes {
        name
      }
    }
  }
`;

export const SITE_FORM_RESPONSIBLES = gql`
  query SiteFormResponsibles @cached {
    responsibles: customers_siteform_aggregate(distinct_on: [who_responsible_id]) {
      nodes {
        accounts_user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const fetchFilteredEmployeesQuery = gql`
  query fetchEmployees($parlorId: Int!, $type: Int!, $style: Int, $date: date, $time: time) {
    accounts_user(
      where: {
        _or: [
          {
            accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
            role: { _in: ["director", "administrator", "master"] }
            is_active: { _eq: true }
            accounts_user_session_types: { sessiontype_id: { _eq: $type } }
            accounts_user_styles: { style_id: { _eq: $style } }
          }
          {
            accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
            role: { _eq: "student" }
            is_active: { _eq: true }
            accounts_user_session_types: { sessiontype_id: { _eq: $type } }
          }
        ]
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
      studentProfile: accounts_studentprofile {
        id
        who_studing
      }
      profile: accounts_profile {
        parlor_id
      }
      motivation: motivation_motivation {
        id
        sessionmotivations: motivation_sessionmotivations(where: { service_id: { _eq: $type } }) {
          id
          percent
        }
      }
      weekends: accountsWeekendsByUserId(
        where: {
          date: { _lte: $date }
          date_end: { _gte: $date }
          from_time: { _lte: $time }
          to_time: { _gte: $time }
        }
      ) {
        id
        from_time
        to_time
        date
        created_at
      }
    }
  }
`;

export const fetchAllEmployeesQuery = gql`
  query fetchAllEmployees($parlorId: Int!, $role: String) {
    accounts_user(
      where: {
        accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
        is_active: { _eq: true }
        role: { _eq: $role }
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
    }
  }
`;

export const fetchItemsQuery = gql`
  query fetchItems($parlor: [Int!]) {
    category: store_category(
      where: { core_parlor_store_available_categories: { parlor_id: { _in: $parlor } } }
    ) {
      id
      name
    }
  }
`;

export const fetchCategoriesAddQuery = gql`
  query fetchCategories($parlor: [Int!]) {
    category: store_category(where: { available: { _eq: true } }) {
      id
      parent_id
      name
    }
  }
`;

// delete value: { _gt: "0" }, from where
export const fetchCategoriesQuery = gql`
  query fetchCategories($parlor: [Int!]) {
    category: store_category(
      where: {
        core_parlor_store_available_categories: { parlor_id: { _in: $parlor } }
        available: { _eq: true }
      }
    ) {
      id
      parent_id
      name
    }
  }
`;

//core_parlor_store_available_categories: { parlor_id: { _in: $parlor } } delete from where fetchCategoriesQueryAvailable
export const fetchCategoriesQueryAvailable = gql`
  query fetchCategories($parlor: [Int!], $stores: [Int!]) {
    category: store_category(
      where: { available: { _eq: true }, store_store: { id: { _in: $stores } } }
    ) {
      value: id
      parent: parent_id
      name
    }
  }
`;

export const fetchStoreCategories = gql`
  query fetchCategories($store: [Int!]) {
    category: store_category(
      where: { available: { _eq: true }, store_store: { id: { _in: $store } } }
    ) {
      id
      parent_id
      name
    }
  }
`;

export const fetchSubCategoriesQueryAvailable = gql`
  query fetchSubCategories($parlor: [Int!], $category: [Int!]) {
    category: store_category(where: { available: { _eq: true }, parent_id: { _in: $category } }) {
      id
      parent_id
      name
    }
  }
`;

export const fetchParlorStoreItemsQuery = gql`
  query fetchItems($category: [Int!], $parlor: Int) {
    parlorstore: store_parlorstore(
      where: {
        _or: [{ store_item: { parent_id: { _is_null: true } } }, { value: { _neq: 0 } }]
        parlor_id: { _eq: $parlor }
        store_item: {
          category_id: { _in: $category }
          store_category: {
            core_parlor_store_available_categories: { parlor_id: { _eq: $parlor } }
            available: { _neq: false }
          }
        }
      }
    ) {
      id
      value
      pricecategory: price_pricecategoryparlorstore {
        percentage
        name
      }
      item: store_item {
        name
        units
        id
        sell_price
        parent_id
        nds
        shop_price
        image
        description
        pay_full_points
        pay_half_points
        category: store_category {
          parent: parent_id
          value: id
        }
        marketable: store_sellorderitems(
          limit: 5
          where: { store_sell: { parlor_id: { _eq: $parlor } } }
        ) {
          details: store_sell {
            created_at
          }
          order_id
        }
        variant: store_attribute {
          id
          name
          value
        }
        store_item_collection_attributes {
          store_collectionattribute {
            store_collectionattributes_attributes {
              store_attribute {
                name
                id
                value
                sell_price
              }
            }
            name
            id
          }
        }
        item_units: store_item_units_news {
          unit: store_unit {
            id
            name
            value
            cost
            title
          }
        }
      }
    }
  }
`;

export const fetchParlorStoreItemVariantsQuery = gql`
  query fetchItemVariants($item: Int!, $parlor: Int) {
    parlorstore: store_parlorstore(
      where: {
        store_item: { store_category: { available: { _neq: false } }, parent_id: { _eq: $item } }
        value: { _gt: 0 }
        parlor_id: { _eq: $parlor }
      }
    ) {
      id
      value
      pricecategory: price_pricecategoryparlorstore {
        percentage
        name
      }
      item: store_item {
        name
        units
        id
        sell_price
        parent_id
        nds
        image
        description
        pay_full_points
        pay_half_points
        category: store_category {
          parent: parent_id
          value: id
        }
        marketable: store_sellorderitems(
          limit: 5
          where: { store_sell: { parlor_id: { _eq: $parlor } } }
        ) {
          details: store_sell {
            created_at
          }
          order_id
        }
        variant: store_attribute {
          id
          name
          value
        }
        store_item_collection_attributes {
          store_collectionattribute {
            store_collectionattributes_attributes {
              store_attribute {
                name
                id
                value
              }
            }
            name
            id
          }
        }
        item_units: store_item_units_news {
          unit: store_unit {
            id
            name
            value
            cost
            title
          }
        }
      }
    }
  }
`;

export const fetchParlorStoreItemsAddQuery = gql`
  query fetchItems($category: [Int!], $parlor: Int) {
    parlorstore: store_parlorstore(
      where: {
        parlor_id: { _eq: $parlor }
        store_item: { category_id: { _in: $category }, available: { _eq: true } }
      }
    ) {
      id
      value
      item: store_item {
        name
        category: store_category {
          parent: parent_id
          value: id
        }
        id
        sell_price
        purchase_price
        parent_id
        nds
        image
        description
        attribute: store_attribute {
          id
          name
          value
        }
        store_item_collection_attributes {
          store_collectionattribute {
            store_collectionattributes_attributes {
              store_attribute {
                name
                id
                value
              }
            }
            name
            id
          }
        }
        store_item_units_news {
          store_unit {
            id
            name
            value
            cost
          }
        }
      }
    }
  }
`;

export const fetchItemsQueryAvailable = (isCRMLocation, userPrice) => gql`
  query fetchItems($category: [Int!], $parlor: Int) {
    items: store_item(where: {
      available: { _eq: true },
      category_id: { _in: $category }
      ${userPrice === 'sell_price' ? 'sell_price_hide: { _neq: true }' : ''}
      ${userPrice === 'provided_price' ? 'provided_price_hide: { _neq: true }' : ''}
      ${userPrice === 'purchase_price' ? 'purchase_price_hide: { _neq: true }' : ''}
      ${userPrice === 'shop_price' ? 'shop_price_hide: { _neq: true }' : ''}
    }) {
      id
      name
      unofficial_item
      units
      category_id
      category: store_category {
        parent: parent_id
        value: id
        name
      }
      item_code
      store_amount
      sell_price
      provided_price
      purchase_price
      purchase_delivery_price
      no_nds_purchase
      wholesale_price
      sell_other_countries_price
      shop_price
      parent_id
      image
      description
      nds
      store_attribute {
        id
        name
      }
      parlor_amount: store_parlorstores(where: {core_parlor: {id: {_eq: $parlor}}}){
        value
      }
      preorders: store_orderitems_aggregate(where: {store_order: {status: {_in: ["100", "150"]}}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      sent: store_orderitems_aggregate(where: {store_order: {status: {_in: ["500", "1500"]}, payment_id: {_is_null: true}}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      expected_items: store_warehouseitems_aggregate(where: {store_warehouselog: {status: {_eq: "100"}}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      ${
        isCRMLocation
          ? 'marketable: store_sellorderitems(limit: 5,  where: { store_sell: { parlor_id: { _eq: $parlor } } }) {details: store_sell {created_at} order_id}'
          : 'marketable: store_orderitems(limit: 5) { id, details: store_order { created_at } }'
      }
      store_item_collection_attributes {
        store_collectionattribute {
          store_collectionattributes_attributes {
            store_attribute {
              name
              id
              value
            }
          }
          name
          id
        }
      }
      item_units: store_item_units_news {
        unit: store_unit {
          id
          name
          value
          cost
          title
        }
      }
    }
  }
`;

export const fetchItemsQueryAvailableCustomer = (userPrice) => gql`
  query fetchItems($category: [Int!], $receiver: Int) {
    items: store_item(where: {
      available: { _eq: true },
      category_id: { _in: $category }
      ${userPrice === 'sell_price' ? 'sell_price_hide: { _neq: true }' : ''}
      ${userPrice === 'provided_price' ? 'provided_price_hide: { _neq: true }' : ''}
      ${userPrice === 'purchase_price' ? 'purchase_price_hide: { _neq: true }' : ''}
      ${userPrice === 'shop_price' ? 'shop_price_hide: { _neq: true }' : ''}
      ${
        userPrice === 'sell_other_countries_price'
          ? 'sell_other_countries_price_hide: { _neq: true }'
          : ''
      }
      ${userPrice === 'wholesale_price' ? 'wholesale_price_hide: { _neq: true }' : ''}
    }) {
      id
      name
      category_id
      category: store_category {
        parent: parent_id
        value: id
      }
      shop_price
      unofficial_item
      item_code
      purchase_price
      purchase_delivery_price
      no_nds_purchase
      sell_other_countries_price
      wholesale_price
      sell_price
      provided_price
      store_amount
      parent_id
      image
      description
      nds
      attribute: store_attribute {
        id
        name
      }
      collections: store_item_collection_attributes {
        collection: store_collectionattribute {
          attributes: store_collectionattributes_attributes {
            attribute: store_attribute {
              name
              id
              value
            }
          }
          name
          id
        }
      }
      item_units: store_item_units_news {
        unit: store_unit {
          id
          name
          value
        }
      }
      prices: store_pricestores(
        where: { store_pricestorecategory: { receiver_id: { _eq: $receiver } } }
      ) {
        value
      }
    }
    categories: store_pricestorecategory(where: { receiver_id: { _eq: $receiver } }) {
      name
    }
  }
`;

export const fetchParlorItemsQuery = gql`
  query fetchItems($stores: [Int!]!) {
    category: store_category(where: { store_id: { _in: $stores }, available: { _eq: true } }) {
      id
      parent_id
      name
      items: store_items(where: { available: { _eq: true } }) {
        id
        name
        units
        category_id
        shop_price
        item_code
        store_amount
        sell_price
        provided_price
        purchase_price
        sell_other_countries_price
        purchase_delivery_price
        wholesale_price
        nds
        parent_id
        image
        description
        is_collection
        can_be_produced
        shop_price_hide
        sell_price_hide
        provided_price_hide
        sell_other_countries_price_hide
        wholesale_price_hide
        unofficial_item
        marketable: store_orderitems(limit: 10) {
          id
          details: store_order {
            created_at
          }
        }
        store_attribute {
          id
          name
          value
        }
        store_item_collection_attributes {
          store_collectionattribute {
            store_collectionattributes_attributes {
              store_attribute {
                name
                id
                value
              }
            }
            name
            id
          }
        }
        item_units: store_item_units_news {
          unit: store_unit {
            id
            name
            value
            cost
            title
          }
        }
        store_itemstorepropositions(where: { status: { _eq: "new" } }) {
          purchase_price
          nds
          id
        }
      }
    }
  }
`;

export const fetchStoreItemsQuery = gql`
  query fetchStoreItems {
    store_item {
      id
      name
      category_id
      sell_price
    }
    store_category {
      id
      name
    }
  }
`;

export const fetchExpenseTypesQuery = gql`
  query fetchExpenseTypes($related_to: String) {
    expenses_expensetype(where: { available: { _eq: true }, related_to: { _eq: $related_to } }) {
      id
      name
    }
  }
`;

export const fetchAdvertTypesQuery = gql`
  query fetchTypes {
    advertising_adverttype(where: { is_active: { _eq: true } }) {
      id
      note
      note_en
      note_ru
      note_pl
      note_uk
      note_old
      kind_type_id
      name
      name_en
      name_ru
      name_pl
      name_uk
      advertising_roles {
        role
      }
      mandatory_advertising
      kind_type: advertising_advertkindtype {
        name
      }
    }
  }
`;

export const fetchAdvertKindTypesQuery = gql`
  query fetchKindTypes {
    advertising_advertkindtype {
      id
      name
    }
  }
`;

// core_parlor(where: {accounts_user: {}}) {
export const fetchParlorsQuery = gql`
  query fetchParlors {
    core_parlor(where: { is_active: { _eq: true } }) {
      id
      status
      name
      is_laser
      is_always_laser
      full_range_of_services
      laser_comment
      native_speaker
      junior_master
      place_of_study
      is_rent
      need_duty_master
      number_of_jobs
      number_maximum_workplaces
      is_flagman
      temporary_inactive_lounge
      director: accounts_user {
        first_name
        last_name
        id
      }
    }
  }
`;
export const fetchUseWorkTax = gql`
  query fetchUseWorkTax {
    motivation_worktax {
      id
      hours
      employee_tax
      employer_tax
      document_template
      salary_netto
      motivation_workcontract {
        id
        name
        description
      }
    }
  }
`;

export const fetchSessionTypesQuery = gql`
  query fetchSessionTypes($userId: Int, $isActive: Boolean) {
    apps_sessions_sessiontype(
      where: {
        accounts_user_session_types: { user_id: { _eq: $userId } }
        is_active: { _eq: $isActive }
      }
    ) {
      id
      show
      is_need_moderate
      name
      name_en
      name_pl
      name_uk
      introductory_text
      locations: apps_sessions_sessiontypelocations {
        id
        value: id
        name
        name_en
        name_ru
        name_pl
        name_uk
        text
      }
      sizes: apps_sessions_sessiontypesizes {
        id
        coefficient
        size
      }
      styles: apps_sessions_sessiontypestyles {
        id
        style: accounts_style {
          id
          name
        }
      }
      introductory_text_en
      introductory_text_pl
      introductory_text_uk
      introductory_text_ru
    }
  }
`;

export const fetchSessionTypesByUserQuery = gql`
  query fetchSessionTypesByUser($user: Int!) {
    types: apps_sessions_sessiontype(
      where: { accounts_user_session_types: { user_id: { _eq: $user } } }
    ) {
      id
      name
    }
  }
`;

export const fetchReceiversQuery = gql`
  query fetchReceivers {
    receivers: customers_receiver(where: { is_active: { _eq: true } }) {
      value: id
      label: name
      price
    }
  }
`;

export const fetchReceiverQuery = gql`
  query fetchReceiver($id: Int!) {
    receiver: customers_receiver_by_pk(id: $id) {
      value: id
      name
      price
    }
  }
`;

export const fetchFindOutOptionsQuery = gql`
  query fetchFindOutOptions {
    customers_findout {
      id
      name
    }
  }
`;

export const fetchAttributesQuery = gql`
  query fetchAttributes {
    attribute: store_attribute {
      value: id
      label: name
    }
  }
`;

export const fetchCollectionAttributesQuery = gql`
  query fetchCollectionAttributes {
    collections: store_collectionattributes {
      value: id
      label: name
    }
  }
`;

export const fetchAppsSesionsSessiotypeTranslation = gql`
  query fetchAppsSesionsSessiotypeTranslation {
    apps_sessions_sessiontype {
      id
      name
    }
  }
`;

export const fetchParlorMarketableItemsQuery = gql`
  query fetchPalorItemsMarketable($parlor: Int, $from: timestamptz!) {
    items: store_item(
      where: {
        available: { _eq: true }
        store_parlorstores: {
          core_parlor: { id: { _eq: $parlor } }
          _and: [{ value: { _gte: 0 } }, { value: { _lte: 5 } }]
        }
        store_sellorderitems: {
          store_sell: { parlor_id: { _eq: $parlor }, created_at: { _gte: $from } }
        }
      }
    ) {
      id
      name
      units
      category_id
      category: store_category {
        parent: parent_id
        value: id
        name
      }
      item_code
      store_amount
      sell_price
      provided_price
      purchase_price
      purchase_delivery_price
      wholesale_price
      sell_other_countries_price
      shop_price
      parent_id
      image
      description
      nds
      store_attribute {
        id
        name
      }
      parlor_amount: store_parlorstores(where: { core_parlor: { id: { _eq: $parlor } } }) {
        value
        parlor_id
      }
      preorders: store_orderitems_aggregate(
        where: { store_order: { status: { _in: ["100", "150"] } } }
      ) {
        aggregate {
          sum {
            value
          }
        }
      }
      expected_items: store_warehouseitems_aggregate(
        where: { store_warehouselog: { status: { _eq: "100" } } }
      ) {
        aggregate {
          sum {
            value
          }
        }
      }
      marketable: store_sellorderitems(
        limit: 5
        where: { store_sell: { parlor_id: { _eq: $parlor } } }
      ) {
        details: store_sell {
          created_at
        }
        order_id
      }
      store_item_collection_attributes {
        store_collectionattribute {
          store_collectionattributes_attributes {
            store_attribute {
              name
              id
              value
            }
          }
          name
          id
        }
      }
      item_units: store_item_units_news {
        unit: store_unit {
          id
          name
          value
          cost
          title
        }
      }
    }
  }
`;

export const fetchOrderItemsDeletedQuery = gql`
  query fetchOrderItemsDeleted($parlor: Int, $from: timestamptz!) {
    store_order(where: { parlor_id: { _eq: $parlor }, created_at: { _gte: $from } }) {
      id
      status
      status_history
    }
  }
`;

export const fetchCustomersCountQuery = gql`
  query fetchCustomersCountQuery(
    $from: date
    $to: date
    $fromT: timestamptz
    $toT: timestamptz
    $nowDay: date
    $nowTime: time
    $validateDate: timestamptz
  ) {
    from_site: apps_sessions_session_aggregate(
      where: {
        date: { _gte: $from, _lte: $to }
        moderated: { _eq: false }
        from_site: { _eq: true }
        apps_sessions_sessionstatus: { status: { _in: [100, 200] } }
        core_parlor: { is_active: { _eq: true } }
        note_admin: { _eq: "" }
      }
      order_by: { date: asc, time: asc }
    ) {
      aggregate {
        count
      }
    }
    leads: accounts_leads_aggregate(where: { call_comment: { _eq: "" } }) {
      aggregate {
        count
      }
    }
    canceled_session_count: apps_sessions_session_aggregate(
      where: {
        apps_sessions_sessionstatus: { status: { _in: [500] } }
        date: { _gte: $from, _lte: $to }
        _or: [
          { customers_formstatus: { hide: { _eq: false } } }
          { form_status_id: { _is_null: true } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    siteform_count: customers_siteform_aggregate {
      aggregate {
        count
      }
    }
    overDueSession: apps_sessions_session_aggregate(
      where: {
        _and: [
          { apps_sessions_sessionstatus: { status: { _nin: [400, 500, 600] } } }
          { date: { _gte: $from } }
          { date: { _lte: $to } }
          {
            _or: [
              { date: { _lt: $nowDay } }
              { _and: [{ date: { _eq: $nowDay } }, { time: { _lt: $nowTime } }] }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    studentNoCallCount: students_student_aggregate(
      where: {
        call_status: { _eq: false }
        call_comment: { _is_null: false }
        created_at: { _gte: $fromT, _lte: $toT }
      }
    ) {
      aggregate {
        count
      }
    }
    studentStartCount: students_student_aggregate(
      where: {
        created_at: { _gte: $fromT, _lte: $toT }
        start_training: { _is_null: false, _gte: $validateDate }
        finished: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    problemsituation_customer: apps_sessions_problemsituation_aggregate(
      where: { user_id: { _is_null: true }, status: { _eq: "wait" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const fetchUsernameByUser = gql`
  query useUserByUsername($username: String!) {
    username: accounts_user(where: { username: { _eq: $username } }, limit: 1) {
      id
      username
      first_name
      last_name
    }
  }
`;

export const fetchSessionTypeSubcategories = gql`
  query sessionSub {
    subSessionType: apps_sessions_sessiontypesubcategories {
      id
      name
      session: apps_sessions_sessiontypesubcategories_session_types(
        where: { apps_sessions_sessiontype: { is_active: { _eq: true } } }
      ) {
        id
        sessionType: apps_sessions_sessiontype {
          id
          show
          is_need_moderate
          name
          name_en
          name_pl
          name_uk
          introductory_text
          locations: apps_sessions_sessiontypelocations {
            id
            value: id
            name
            name_en
            name_ru
            name_pl
            name_uk
            text
          }
          sizes: apps_sessions_sessiontypesizes {
            id
            coefficient
            size
          }
          styles: apps_sessions_sessiontypestyles {
            id
            style: accounts_style {
              id
              name
            }
          }
          introductory_text_en
          introductory_text_pl
          introductory_text_uk
          introductory_text_ru
        }
      }
    }
  }
`;
export const fetchSessionSize = gql`
  query sessionSize {
    size: apps_sessions_sessiontypesize {
      id
      size
    }
  }
`;

export const fetchPhotoDiplom = gql`
  query masterPhoto($id: Int!) {
    accounts_awardsdiplomas(where: { user_id: { _eq: $id } }) {
      id
      cooperation_period
    }
  }
`;

export const GADGET_NAME = gql`
  query gadgetName {
    gadget: accounts_gadgetname {
      id
      name
    }
  }
`;

export const GadgetList = gql`
  query MyQuery(
    $type: Int
    $role: String
    $status: String
    $category: String
    $depart: Int
    $salon: Int
    $decommissioned: Boolean
    $harmonization: Boolean
  ) {
    gadget: accounts_gadgetname {
      id
      name
    }
    accounts_usergadget(
      where: {
        parlor_id: { _eq: $salon }
        gadget_id: { _eq: $type }
        _or: [{ user_id: { _is_null: true } }, { accountsUserByUserId: { role: { _eq: $role } } }]
        status: { _eq: $status }
        category: { _eq: $category }
        department_id: { _eq: $depart }
        decommissioned: { _eq: $decommissioned }
        harmonization: { _eq: $harmonization }
      }
      order_by: { id: desc }
    ) {
      id
      created_at
      description
      estimated_cost
      is_returned
      account
      harmonization
      defect_photo
      corrected_defect_photo
      defect_comment
      password
      serial_number
      status
      updated_at
      updated_by_id
      department_id
      category
      user_id
      decommissioned
      guarantee_file
      guarantee_date
      quantity
      logs: accounts_usergadgetlogs(order_by: { id: desc }) {
        id
        field_name
        action
        new_value
        old_value
        timestamp
        user_gadget_id
        user_id
        user: accounts_user {
          id
          first_name
          last_name
        }
      }
      parlor: core_parlor {
        id
        name
      }
      accounts_gadgetname {
        id
        name
      }
      accountsUserByUserId {
        id
        first_name
        last_name
      }
    }
  }
`;
export const fetchStudentsSearch = gql`
  query phone($phone: String) {
    students_student(
      where: { accountsUserByStudentId: { accounts_profile: { phone_number: { _eq: $phone } } } }
    ) {
      id
    }
  }
`;
export const fetchCouchPrice = gql`
  query couchPrice {
    core_couchprice {
      id
      name
      price
      type
    }
  }
`;
export const SKILL_BOX = gql`
  query masterPhoto(
    $user_id: Int
    $is_moderation: Boolean
    $from: timestamptz
    $to: timestamptz
    $role: String
  ) {
    accounts_awardsdiplomas(
      where: {
        user_id: { _eq: $user_id }
        is_moderation: { _eq: $is_moderation }
        created_at: { _gte: $from, _lte: $to }
        accountsUserByUserId: { role: { _eq: $role } }
      }
      order_by: { id: desc }
    ) {
      id
      created_at
      image
      is_moderation
      created_by_id
      when_training_completed
      training_take_place
      type_payment
      cooperation_period
      show_on_site
      agreement
      file
      currentUser: accountsUserByUserId {
        id
        last_name
        first_name
        role
      }
      user: accounts_user {
        id
        last_name
        first_name
        role
      }
      style: accounts_awardsdiplomas_session_styles {
        id
        accounts_style {
          id
          name
        }
      }
      type: accounts_awardsdiplomas_session_types {
        id
        apps_sessions_sessiontype {
          id
          name
        }
      }
    }
  }
`;

export const SKILL_BOX_MODERATION_CONT = gql`
  query SkillBoxCount($user_id: Int) {
    accounts_awardsdiplomas_aggregate(
      where: { user_id: { _eq: $user_id }, is_moderation: { _eq: false } }
    ) {
      ag: aggregate {
        count
      }
    }
  }
`;

export const DATE_COOPERATION = gql`
  query dateCooperation($user_id: Int) {
    accounts_awardsdiplomas(where: { user_id: { _eq: $user_id }, is_moderation: { _eq: true } }) {
      id
      cooperation_period
    }
  }
`;

export const fetchEmployeesQueryByRole = gql`
  query fetchEmployees($parlorId: Int!, $role: [String!]) {
    accounts_user(
      where: {
        accounts_profile: { accounts_profile_parlors: { parlor_id: { _eq: $parlorId } } }
        is_active: { _eq: true }
        role: { _in: $role }
      }
    ) {
      id
      role
      username
      first_name
      middle_name
      last_name
      accounts_profile {
        id
        accounts_mentorrank {
          id
          name
        }
      }
      motivation: motivation_motivation {
        mentor_percent
      }
      countStudent: studentsStudentsByMentorId_aggregate {
        ag: aggregate {
          count
        }
      }
    }
  }
`;
export const fetchTypeStudentPayments = gql`
  query typeStudentPayments {
    type_payments: students_typestudentpayment {
      id
      name
      parts_start_lesson
    }
  }
`;
